<div class="footer-area">
    <div class="top-footer">
        <div class="container">
            <div class="row justify-content-between ">
                <div class="col-md-4">
                    <div class="footer-logo">
                        <img src="../../../assets/images/ctnewlogo.svg" class="" alt="">
                    </div>
                    <!-- <h3>Crescent Teleservices LLP
                        <small>a consortium partnership with PKIPL</small>
                    </h3> -->
                        <p>Telecom – Fiber - Power – Retail - EV Charging </p>
                            <ul class="footer-link media-link">
                                <!-- <li class="media-fa"><a href="" ><i class="fab fa-facebook-f"></i></a> </li> -->
                                <li class="media-fa"><a href="" target="blank"><i
                                      class="fab fa-instagram"></i></a></li>
                                <li class="media-fa"><a href="" target="blank"><i
                                      class="fab fa-linkedin-in"></i></a></li>
                                <li class="media-fa"><a href="" target="blank"><i
                                      class="fab fa-twitter"></i></a></li>
                                <li class="media-fa"><a href="" target="blank"><i
                                      class="fab fa-youtube"></i></a></li>
                              </ul>
                </div>
    
                <div class="col-md-2 ">
                    <h3>Useful Links</h3>
                    <ul class="footer-link">
                        <li routerLink="./">Home</li>
                        <li routerLink="./about-us">About Us</li>
                        <li routerLink="./services">Our services</li>
                        <li routerLink="./case-studies">Case Studies</li>
                        <li routerLink="./contact-us">Contact Us</li>
                        <!-- <li>Privacy Policy</li> -->
                    </ul>
                </div>
                <div class="col-md-2 ">
                    <h3>Our Service</h3>
                    <ul class="footer-link">
                        <li routerLink="./services">Projects</li>
                        <li routerLink="./services">Operation</li>
                        <li routerLink="./services">Maintenance </li>
                        <li routerLink="./services">Engineering</li>
                        
                    </ul>
                </div>
                <div class="col-md-4">
                    <h3>Our Expertise</h3>
                    <ul class="footer-link">
                        <li>Cell-Site Infrastructure Expert (CIE)</li>
                        <li>Data Centre/Msc Infrastructure Expert (DIE)</li>
                        <li>FTTH/OFC Infrastructure Expert (FIE)</li>
                        <li>Retail/Warehouse Infrastructure Expert (RIE)</li>
                        <li>EV Charging Station Infrastructure Expert (EVIE)</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-footer">
        <p>© 2021-2022 Crescent Teleservices LLP. All rights reserved.</p>
    </div>
</div>
