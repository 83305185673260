import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isNavCollapsed = true;
  url: string;
  constructor() { }

  ngOnInit(): void {
  }

  toggleCollapsed() {
    this.isNavCollapsed = !this.isNavCollapsed;
  }

  goToLink(url: string){
    window.open(url, '_blank');
}

}
