<ngb-carousel>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="../../../assets/images/hero-banner-01.webp" class="w-100" alt="">
      </div>
  
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="../../../assets/images/hero-banner-02.webp" class="w-100" alt="">
      </div>
      
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="../../../assets/images/hero-banner-03.webp" class="w-100" alt="">
      </div>
    
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="../../../assets/images/hero-banner-04.webp" class="w-100" alt="">
      </div>
    
    </ng-template>
</ngb-carousel>
