import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from './core/core.module';
import { AgGridModule } from 'ag-grid-angular';

 const ParentRoute: Routes = [
  {
    path: '', component: AppComponent, children: [
      {
        path: '', loadChildren: () => import('./viewer/viewer.module').then(m => m.ViewerModule)
      },
      {
        path: '', loadChildren: () => import('./admin/admin.module').then(r => r.AdminModule)
      },
    ]
  },
  { path: '**', redirectTo: ''}
 ];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    RouterModule.forRoot(ParentRoute),
    NgbModule,
  ],

  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
