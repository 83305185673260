<nav class="navbar navbar-expand-lg">
  <a class="navbar-brand hand" routerLink="/" routerLinkActive="router-link-active" >
    <div class="logo-main" >
      <img src="../../../assets/images/Logo-lg.svg" class="logo-header"  alt="">
    </div>
  </a>
  <button class="navbar-toggler" (click)="toggleCollapsed()" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <i class="fas fa-bars"></i>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isNavCollapsed">
    <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
      <li class="nav-item" (click)="isNavCollapsed = true" routerLinkActive="active" >
        <a class="nav-link" routerLink="/" >Home</a>
      </li>
      <li class="nav-item" (click)="isNavCollapsed = true"  routerLinkActive="active" >
        <a class="nav-link" routerLink="./about-us" >About Us</a>
      </li>
      <li class="nav-item" (click)="isNavCollapsed = true"  routerLinkActive="active">
        <a class="nav-link"  routerLink="./services">Our Services</a>
      </li>
      <li class="nav-item" (click)="isNavCollapsed = true"  routerLinkActive="active">
        <a class="nav-link" routerLink="./case-studies" >Case Studies</a>
      </li>
      <!-- <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Our Solution
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item" href="#">Action</a></li>
          <li><a class="dropdown-item" href="#">Another action</a></li>
          <li><hr class="dropdown-divider"></li>
          <li><a class="dropdown-item" href="#">Something else here</a></li>
        </ul>
      </li> -->
      <li class="nav-item" (click)="isNavCollapsed = true" routerLinkActive="active">
        <a class="nav-link" routerLink="./employpreniourship" >Employpreneurship</a>
      </li>
      <li class="nav-item" (click)="isNavCollapsed = true" routerLink="./contact-us" routerLinkActive="activeClass">
        <a class="nav-link" >Contact Us</a>
      </li>
      <!--<li class="nav-item" (click)="isNavCollapsed = true" routerLink="./admin/login" routerLinkActive="activeClass">
        <a class="nav-link" >Sign In</a>
      </li>-->

    </ul>
    <!-- <form class="d-flex">
      <button class="btn btn-primary" type="button" routerLink="./become-partner">Become Partner</button>
    </form> -->
  </div>
  </nav>
