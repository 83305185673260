import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroSliderComponent } from './hero-slider/hero-slider.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { AgGridModule } from 'ag-grid-angular';
import { MatDialogModule } from '@angular/material/dialog';
import { SnackbarComponent } from './snack-bar/snackbar/snackbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CountUpModule } from 'ngx-countup';


@NgModule({
  declarations: [
    HeroSliderComponent,
    SnackbarComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    CountUpModule
  ],
  exports: [
    HeroSliderComponent,
    NgbModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    AgGridModule,
    MatDialogModule,
    MatSnackBarModule,
    CountUpModule
  ]
})
export class SharedModule { }
